import jobCreateSlices from "./jobCreateSlices";
import logoUploadSlice from "./logoUploadSlice";
import getAllJobSlice from "./getAllJobSlices";
import jobDetailsSlice from "./jobDetailsSlice";
import deleteCreatedJobSlice from "./deleteCreatedJobSlice";
import getAllResumeSlice from "./getAllResumeSlices";
import getLocationSlices from "./getLocationSlice";
import generateJobDescriptionSlice from "./generateJobDescriptionSlice";
import getFirmDetailsSlice from "./getFirmDetailsSlice";
import updateFirmDetailsSlice from "./updateFirmDetailsSlice";
import generateQuestionSlice from "./generateQuestionSlice";
import getFilterJobListsSlice from "./getFilterJobListsSlice";
import getJobFilterSlice from "./getJobFilterSlice";
import getJobMemberAndGroupSlice from "./getJobMemberAndGroupSlice";
import getPrivateJobUrlSlice from "./getPrivateJobBoardUrl";
import checkPrivateJobUrlSlice from "./checkPrivateJobBoardUrl";
import bulkUpdateApplicationSlice from "./bulkUpdateApplicationSlice";
import getAiSkillsSlice from "./getAiSkillsSlice";
import getRolesSlices from "./getRolesSlice";

export const allJobSlices = {
  jobCreateData: jobCreateSlices,
  logoUploadfile: logoUploadSlice,
  allJobList: getAllJobSlice,
  getJobDetails: jobDetailsSlice,
  deleteJob: deleteCreatedJobSlice,
  allResumeList: getAllResumeSlice,
  locationList: getLocationSlices,
  jobDescription: generateJobDescriptionSlice,
  getFirmDetailsData: getFirmDetailsSlice,
  updateFirmDetailsData: updateFirmDetailsSlice,
  generateQuestionData: generateQuestionSlice,
  getFilterJobLists: getFilterJobListsSlice,
  getJobFilter: getJobFilterSlice,
  getJobMemberAndGroup: getJobMemberAndGroupSlice,
  getPrivateJobBoardUrl: getPrivateJobUrlSlice,
  checkPrivateJobBoardUrl: checkPrivateJobUrlSlice,
  bulkUpdateApplication: bulkUpdateApplicationSlice,
  generateAiSkills: getAiSkillsSlice,
  getRoles: getRolesSlices
};
