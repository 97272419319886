import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getRoles = createAsyncThunk("getRoles", async (inboxData: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + JOB_CREATE_BASE
    }/api/v1/ai_features/get_roles?searchTerm=${inboxData?.searchTerm}&skip=${inboxData?.skip
    }&limit=${inboxData?.limit}`
  );
  return data;
});

const getRolesSlice = createSlice({
  name: "getRoles",
  initialState: {
    loading: false,
    rolesData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.rolesData = action.payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getRolesSlice.reducer;
