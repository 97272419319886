import React, { useEffect, useState } from "react";
import AboutCompany from "../components/JobCreations/AboutCompany";
import layoutTwo from "../components/layout/layoutTwo";
import Breadcrumb from "../components/StyledComponents/V2/Breadcrumb";
import BasicDetails from "../components/JobCreations/BasicDetails";
import SkillAndExp from "../components/JobCreations/SkillAndExp";
import Description from "../components/JobCreations/Description";

import AllQuestion from "../components/JobCreations/AllQuestion";
import { generateQuestion } from "../redux/slices/JobSlices/generateQuestionSlice";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getFirmDetails } from "../redux/slices/JobSlices/getFirmDetailsSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { Icon } from "@iconify/react";
import Previews from "../components/JobCreations/Preview/Previews";

import { jobDetails } from "../redux/slices/JobSlices/jobDetailsSlice";

const JobCreations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";
  const id = searchParams.get("id") || null;

  const { firmDetailsData, errorData, loading } = useAppSelector(
    (state: any) => state.getFirmDetailsData
  );

  const { jobDetailsData } = useAppSelector(
    (state: any) => state.getJobDetails
  );

  const [step, setStep] = useState(1);
  const [jobDataChanged, setJobDataChanged] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [jobData, setJobData] = useState<any>({
    _id: "",
    tagline: "",
    job_title: "",
    job_roles: [] as string[],
    job_type: -1,
    redirect: "",
    time_taken: 0,
    experience_required: -1 as number,
    max_experience_required: -1 as number,
    skills: [] as string[],
    skill_options: {
      global_options: [] as string[],
      custom_options: [] as string[]
    },
    firm_overview: firmDetailsData?.description,
    description: "",
    department: "",
    experience: "",
    display_picture: firmDetailsData?.display_picture,
    address: {
      state: "",
      country_code: "",
      cities: [] as string[],
      country: ""
    },
    organisations: [],
    hidden: false,
    closed: false,
    schedule_date: null,
    start_date: "",
    deadline: "",
    website: "",
    alert: false,
    industries: [] as string[],
    job_questions: [] as string[],
    tags: [] as string[],
    disable_sat_act_requirement: false,
    job_score_enabled: false,
    job_score_parameters: null,
    custom_scoring_attributes: [],
    job: "",
    objectives: [] as string[],
    role_details: "",
    role_type: "",
    candidate_types: [] as string[],
    firm_logo: "",
    draft_status: {
      is_draft: false
    },
    under_review: false,
    reviewed: false,
    internal_job_id: "",
    ai_scoring_enabled: true
  });

  const [showedAiDialog, setShowedAiDialog] = useState<{
    enabled: boolean;
    disabled: boolean;
  }>({ enabled: false, disabled: false });


  useEffect(() => {
    if (!sessionStorage.getItem("reloaded")) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }
  }, [])

  useEffect(() => {
    dispatch(getFirmDetails());
  }, []);

  useEffect(() => {
    if (id != null) dispatch(jobDetails(id));
  }, [id]);

  useEffect(() => {
    if (errorData || firmDetailsData) {
      if (errorData?.message === "404" || firmDetailsData?.get_again) {
        navigate("?view=company-details");
      } else if ((view === "" || view !== "basic-details") && id === null) {
        navigate("?view=basic-details");
      }
    }
  }, [errorData, firmDetailsData, loading]);

  useEffect(() => {
    if (jobDetailsData && id != null) {
      setJobData(jobDetailsData);
    }
  }, [jobDetailsData, id]);

  useEffect(() => {
    if (view === "basic-details") {
      setStep(1);
    } else if (view === "skill") {
      setStep(2);
    } else {
      setStep(3);
    }
  }, [view]);

  const generateAIHandler = () => {
    setOpen(true);
    dispatch(
      generateQuestion({
        job_title: jobData?.job_title,
        job_roles: jobData?.job_roles,
        job_industries: jobData?.industries,
        job_skills: jobData?.skills,
        job_location: `${jobData?.address?.country}, ${jobData?.address?.state}`,
        experince: jobData?.experience,
        role_type: jobData?.role_type,
        firm_overview: jobData?.firm_overview
      })
    );
  };

  useEffect(() => {
    console.log('here', jobData);
  }, [jobData])

  return (
    <div className="job-creation">
      <Breadcrumb label="Jobs" labelTwo="Create job" labelLink="/jobs" />
      {view === "company-details" && (
        <AboutCompany jobData={jobData} setJobData={setJobData} />
      )}

      {view !== "company-details" && view !== "preview" && (
        <div className="field-body">
          {view !== "questions" && (
            <div className="top">
              <h1>Enter Basic Job Details</h1>
              <div className="top-left">
                <p className="step">
                  <span>{step}</span> of 3
                </p>
                <div className={"progress"}>
                  <BorderLinearProgress
                    variant="determinate"
                    color="primary"
                    value={(step / 3) * 100}
                    className="progress-bar"
                  />
                </div>
              </div>
            </div>
          )}

          {view === "questions" && (
            <div className="top">
              <div className="top-body">
                <h1>Ask question to your applicants</h1>
                <LoadingButton
                  onClick={generateAIHandler}
                  variant="contained"
                  className="btn-contained"
                  loadingPosition="end"
                // loading={jobDescriptionLoading}
                >
                  Generate with AI
                  <Icon icon="fluent-emoji:sparkles" className="icon" />
                </LoadingButton>
              </div>
            </div>
          )}

          {view === "basic-details" && (
            <BasicDetails
              jobData={jobData}
              setJobData={setJobData}
              setStep={setStep}
              step={step}
              setJobDataChanged={setJobDataChanged}
              setInitialSetupDone={setInitialSetupDone}
              showedAiDialog={showedAiDialog}
              setShowedAiDialog={setShowedAiDialog}
            />
          )}

          {view === "skill" && (
            <SkillAndExp
              jobData={jobData}
              setJobData={setJobData}
              step={step}
              setStep={setStep}
              setJobDataChanged={setJobDataChanged}
              setInitialSetupDone={setInitialSetupDone}
              initialSetupDone={initialSetupDone}
            />
          )}

          {view === "description" && (
            <Description
              jobData={jobData}
              setJobData={setJobData}
              step={step}
              jobDataChanged={jobDataChanged}
              setStep={setStep}
              setJobDataChanged={setJobDataChanged}
            />
          )}

          {view === "questions" && (
            <AllQuestion
              jobData={jobData}
              setJobData={setJobData}
              setOpen={setOpen}
              open={open}
              setStep={setStep}
            />
          )}
        </div>
      )}

      {view === "preview" && (
        <Previews jobData={jobData} setJobData={setJobData} />
      )}
    </div>
  );
};

export default layoutTwo(JobCreations);
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(0, 52, 187, 0.7) !important"
    // margin: "3px 0px",
    // marginLeft: 20, // Add left margin here
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "transparent"
  }
}));
