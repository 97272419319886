import React from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import { IconButton, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks";

interface EventCardProps {
  event: any;
}

const EventListCard = ({ event }: EventCardProps) => {
  const navigate = useNavigate();
  const { permissionList } = useAppSelector(
    (state) => state.getPermission
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const eventViewHandler = (e: any) => {
    navigate(`/event/${event._id}`);
    e.stopPropagation();
  };

  const registrantViewHandler = (e: any) => {
    navigate(`/event/${event._id}?tab=registrant-details`);
    e.stopPropagation();
  };

  const certificateViewHandler = (e: any) => {
    navigate(`/event/${event._id}/create-certificate`);
    e.stopPropagation();
  };

  return (
    <div className="event-list-card" onClick={eventViewHandler}>
      <div className="img-wrapper">
        <img src={event.display_picture} alt="" />
      </div>

      <div className="card-content">
        <div className="card-body">
          <div className="main">
            <p className="date-time">
              {moment(event?.start_date).format("ll")}
              <Icon icon="ph:dot-outline-fill" />
              {moment(event?.start_date).format("LT")}
            </p>
            <p onClick={eventViewHandler} className="event-name">
              {event?.name}
            </p>
            <p className="city-applicants">
              {event?.address?.city && (
                <>
                  <span className="city">{event?.address?.city}</span>
                </>
              )}
              {event?.registrations_count &&
                event?.registrations_count > 50 && (
                  <>
                    <Icon icon="ph:dot-outline-fill" />
                    <div>{event?.registrations_count}+ Applicants</div>
                  </>
                )}
            </p>
            <p className="event-scheduled-date">
              Posted on {moment(event?.start_date).format("ll")}
            </p>
          </div>

          <div className="icon-cta">
            <IconButton onClick={handleClick}>
              <Icon icon="charm:menu-kebab" width={20} height={20} />
            </IconButton>
            <Menu
              className="event-card-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <MenuItem onClick={eventViewHandler}>
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:eye" width={20} height={20} />
                  <span>View</span>
                </div>
              </MenuItem>
              {Date.now() < Number(new Date(event.end_date)) && (
                <>
                  {/* <MenuItem> */}
                  {/*   <div className="menu-item-wrapper"> */}
                  {/*     <Icon icon="lucide:pencil" width={20} height={20} /> */}
                  {/*     <span>Request an Edit</span> */}
                  {/*   </div> */}
                  {/* </MenuItem> */}
                  <MenuItem onClick={registrantViewHandler}>
                    <div className="menu-item-wrapper">
                      <Icon icon="lucide:user-round" width={20} height={20} />
                      <span>Registrants</span>
                    </div>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={(e) => {
                  handleClose(e);
                  e.stopPropagation();
                  navigate(`/event/${event._id}/training-materials`);
                }}
              >
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:book-open-text" width={20} height={20} />
                  <span>Training resources</span>
                </div>
              </MenuItem>
              <MenuItem onClick={certificateViewHandler}>
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:award" width={20} height={20} />
                  <span>Certificates</span>
                </div>
              </MenuItem>
              {permissionList?.buttons?.event_delete_button && (
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    e.stopPropagation();
                  }}
                >
                  <div className="menu-item-wrapper">
                    <Icon icon="lucide:trash" width={20} height={20} />
                    <span>Delete</span>
                  </div>
                </MenuItem>
              )}
            </Menu>
          </div>
          <div
            className="status-chip"
            data-event-draft={event?.event_settings?.draft_event}
            data-registration-closed={
              Date.now() > Number(new Date(event.end_date))
            }
          >
            {event?.event_settings?.draft_event
              ? "Draft"
              : Date.now() > Number(new Date(event.end_date))
                ? "Registrations Closed"
                : "Active"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventListCard;
