import React, { useEffect, useState } from "react";

import layoutTwo from "../components/layout/layoutTwo";
import Breadcrumb from "../components/StyledComponents/V2/Breadcrumb";
import SearchAndFilter from "../components/JobCreations/JobList/SearchAndFilter";
import CardGrid from "../components/JobCreations/JobList/CardGrid";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { postFilterJob } from "../redux/slices/JobSlices/getFilterJobListsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import Details from "../components/JobCreations/JobList/Details";
import { getJobFilter } from "../redux/slices/JobSlices/getJobFilterSlice";
import { Icon } from "@iconify/react";
import EcnButton from "../components/StyledComponents/EcnButton";
import Loader from "../components/UI/Loader";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";

const JobsLists = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";
  const page = searchParams.get("page") || "1";

  const { userInfo } = useAppSelector((state) => state.userDetails);

  const { filterJobData, loading: filterJobDataLoading } = useAppSelector(
    (state: any) => state.getFilterJobLists
  );
  const { deletedCreatedJobData, loading } = useAppSelector(
    (state: any) => state.deleteJob
  );
  const { jobData: successJobData, error } = useAppSelector(
    (state: any) => state.jobCreateData
  );

  const [filterData, setFilterData] = useState<any>({
    status: [],
    job_type: [],
    location: []
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(
      postFilterJob({
        job_status: [],
        job_types: [],
        job_locations: [],
        query: "",
        skip: (Number(page) - 1) * 10,
        limit: Number(page) * 10
      })
    );
  }, [deletedCreatedJobData, successJobData, page]);

  useEffect(() => {
    dispatch(getJobFilter());
  }, []);

  useEffect(() => {
    if (view === "") {
      navigate("?view=list");
    }
  }, [view]);

  const removefilter = (type: any, value: any) => {
    if (type === "status") {
      const updatedStatus = filterData?.status.filter(
        (status: string) => status !== value
      );
      setFilterData((prevFilterData: any) => ({
        ...prevFilterData,
        status: updatedStatus
      }));

      dispatch(
        postFilterJob({
          job_status: updatedStatus,
          job_types: filterData?.job_type,
          job_locations: filterData?.location,
          query: searchTerm
        })
      );
    }
    if (type === "job_type") {
      const updatedStatus = filterData?.job_type.filter(
        (status: string) => status !== value
      );
      setFilterData((prevFilterData: any) => ({
        ...prevFilterData,
        job_type: updatedStatus
      }));

      dispatch(
        postFilterJob({
          job_status: filterData?.status,
          job_types: updatedStatus,
          job_locations: filterData?.location,
          query: searchTerm
        })
      );
    }
    if (type === "location") {
      const updatedStatus = filterData?.location.filter(
        (status: string) => status !== value
      );
      setFilterData((prevFilterData: any) => ({
        ...prevFilterData,
        location: updatedStatus
      }));

      dispatch(
        postFilterJob({
          job_status: filterData?.status,
          job_types: filterData?.job_type,
          job_locations: updatedStatus,
          query: searchTerm
        })
      );
    }
    if (type === "query") {
      setSearchTerm("");
      dispatch(
        postFilterJob({
          job_status: filterData?.status,
          job_types: filterData?.job_type,
          job_locations: filterData?.location,
          query: ""
        })
      );
    }
  };

  return (
    <>
      <div className="job-lists">
        {view === "list" && (
          <>
            <EcnBreadCrumbs>
              <p>Jobs</p>
            </EcnBreadCrumbs>
            <h1 className="title">Jobs</h1>
            {filterJobData?.result?.length === 0 &&
              filterData?.status?.length === 0 &&
              filterData?.job_type?.length === 0 &&
              filterData?.location?.length === 0 &&
              searchTerm === "" ? (
              <div className="fallback">
                <img src="https://s1.ecnstatic.com/ecn/images/man with binoculars.png" />
                <p>No jobs found!</p>
                <EcnButton
                  variant="contained"
                  className="create"
                  onClick={() => { navigate("/job-creations"); window.location.reload(); }}
                >
                  <Icon icon="lucide:plus" width={20} height={20} /> Create
                  Job
                </EcnButton>
              </div>
            ) : (
              <>
                <SearchAndFilter
                  setFilterData={setFilterData}
                  filterData={filterData}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                />
                {(filterData?.status?.length != 0 ||
                  filterData?.job_type?.length != 0 ||
                  filterData?.location?.length != 0 ||
                  searchTerm != "") && (
                    <div className="filter-chip">
                      {filterData?.status?.map((item: any) => (
                        <p
                          className="chip"
                          onClick={() => removefilter("status", item)}
                        >
                          {item} <Icon icon="ic:baseline-close" />
                        </p>
                      ))}
                      {filterData?.job_type?.map((item: any) => (
                        <p
                          className="chip"
                          onClick={() => removefilter("job_type", item)}
                        >
                          {item} <Icon icon="ic:baseline-close" />
                        </p>
                      ))}
                      {filterData?.location?.map((item: any) => (
                        <p
                          className="chip"
                          onClick={() => removefilter("location", item)}
                        >
                          {item} <Icon icon="ic:baseline-close" />
                        </p>
                      ))}
                      {/* {searchTerm != "" && (
                      <p
                        className="chip"
                        onClick={() => removefilter("query", searchTerm)}
                      >
                        {searchTerm} <Icon icon="ic:baseline-close" />
                      </p>
                    )} */}
                    </div>
                  )}

                {filterJobData?.result?.length === 0 &&
                  (filterData?.status?.length != 0 ||
                    filterData?.job_type?.length != 0 ||
                    filterData?.location?.length != 0 ||
                    searchTerm != "") ? (
                  <div className="fallback">
                    <img src="https://s1.ecnstatic.com/ecn/images/white binoculars.png" />
                    <p>No results found!</p>
                  </div>
                ) : (
                  <CardGrid />
                )}
              </>
            )}
          </>
        )}
        {view === "details" && <Details />}
      </div>
    </>
  );
};

export default layoutTwo(JobsLists);
