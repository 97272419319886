import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import layoutTwo from "../components/layout/layoutTwo";
import AccessTab from "../components/Permission/AccessTab";
import { useAppSelector } from "../redux/hooks";

const Permission = () => {
  const navigate = useNavigate();
  const { userInfo, loading } = useAppSelector((state) => state.userDetails);
  const { permissionList } = useAppSelector((state) => state.getPermission);

  useEffect(() => {
    if (!permissionList?.buttons?.user_management) {
      navigate("/dashboard");
    }
  }, [permissionList]);

  return (
    <div className="permission">
      <div className="breadcrumb">
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 20 20"
          fill="none"
          // onClick={() => navigate("/job")}
          style={{ cursor: "pointer", marginRight: "10px" }}
        >
          <path
            d="M2.5 7.49935L10 1.66602L17.5 7.49935V16.666C17.5 17.108 17.3244 17.532 17.0118 17.8445C16.6993 18.1571 16.2754 18.3327 15.8333 18.3327H4.16667C3.72464 18.3327 3.30072 18.1571 2.98816 17.8445C2.67559 17.532 2.5 17.108 2.5 16.666V7.49935Z"
            stroke="#212121"
            strokeOpacity="0.6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#212121"
            strokeOpacity="0.6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="dots"></div>
        <p>Permissions</p>
      </div>

      <h1 className="title">User Management</h1>
      {/* <p className="sub-title">
        Role-based access control ensures employees only have access to the
        processes and programs necessary.
      </p> */}
      <AccessTab />
    </div>
  );
};

export default layoutTwo(Permission);
