import { Pagination, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { getEventsList } from "../../redux/slices/EventSlices/getEventListSlice";
import EventListCard from "../Events/EventListCard";
import { useQueryParams } from "../../helpers/HOC/useQueryParams";

const PAGE_SIZE = 10;

const EventsTab = () => {
  const [searchParams, updateQueryParams] = useQueryParams();
  const searchQuery = searchParams.get("q");
  const [page, setPage] = useState<number>(1);
  const dispatch = useAppDispatch();

  const { eventListData, loading: eventsLoading } = useAppSelector(
    (state) => state.getAllEvents
  );

  useEffect(() => {
    dispatch(
      getEventsList({ skip: (page - 1) * PAGE_SIZE, limit: page * PAGE_SIZE })
    );
  }, []);

  useEffect(() => {
    if (searchQuery) setPage(1);
    const currentPage = searchQuery ? 1 : page;
    dispatch(
      getEventsList({
        skip: (currentPage - 1) * PAGE_SIZE,
        limit: currentPage * PAGE_SIZE,
        q: searchQuery || ""
      })
    );
  }, [searchQuery]);

  const pageChangeHandler = (e: any, page: any) => {
    updateQueryParams({ page: page });
    setPage(page);
    dispatch(getEventsList({ skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE }));
    const pageElement = document.querySelector(".page-right");
    if (pageElement) {
      pageElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="events-tab">
      <div className="event-cards">
        {eventsLoading ? (
          Array(5)
            .fill(0)
            .map((item: any, index) => (
              <div key={index} className="event-card-skeleton">
                <div
                  style={{
                    padding: 10,
                    width: "250px"
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"90%"}
                    height={180}
                    style={{ margin: 0, padding: 0, borderRadius: 10 }}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    padding: 10
                  }}
                >
                  <Skeleton width={"50%"} height={25} />

                  <Skeleton width={"40%"} height={25} />
                  <Skeleton width={"20%"} height={25} />
                  <Skeleton width={"30%"} height={25} />
                </div>
              </div>
            ))
        ) : (
          <>
            {eventListData?.result?.map((event: any) => (
              <EventListCard event={event} />
            ))}
          </>
        )}
      </div>
      <div className="pagination-wrapper">
        <Pagination
          page={page}
          onChange={pageChangeHandler}
          count={
            eventListData?.total_count
              ? Math.ceil(eventListData.total_count / PAGE_SIZE)
              : 1
          }
          color="primary"
          className="job-pagination"
        />
      </div>
    </div>
  );
};
export default EventsTab;
